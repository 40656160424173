.txt-rt {
  text-align: right;
}

.txt-ctr {
  text-align: center;
}

.lightBlue {
  background-color: lightblue !important;
}

li.nav-item.active {
  background-color: #007bff;
  border-color: #007bff;
  color: white;
}

.seasonDropdownButton div {
  max-height: 500px;
  overflow-y: auto;
}

.seasonDropdownButton button.btn {
  padding: 8px;
}

.ht500 div {
  max-height: 500px;
  overflow-y: scroll;
}
