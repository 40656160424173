nav.matadorNav {
  background-color: #007bff;
  font-size: 1.5rem;
  border-radius: 3px;
}

.white {
  color: #ffffff !important;
}

.navbar-brand.matadorBrand {
  font-size: 1.75rem;
  color: #ffffff !important;
}

.jumbotron,
.container {
  font-family: Palatino, URW Palladio L, serif;
}

.f-125 {
  font-size: 1.25rem;
}

.f-150 {
  font-size: 1.5rem;
}

.bg-black {
  background-color: #000000;
}

@media screen and (max-width: 900px) {
  nav.matadorNav {
    font-size: 1.5rem;
    border-radius: 3px;
    margin-bottom: 10px;
  }

  .jumbotron {
    display: none;
    margin-bottom: 5px;
  }

  .columnVenue {
    width: 100%;
  }
}
